import { memo, useRef } from "react";
import RouterGenerator from "src/core/routes/RouterGenerator";
import useAuth from "src/hooks/modules/useAuth";
import useAuthRoutes from "src/routes/authRoutes";
import getListRoute from "src/routes/listRoutes";
import routePaths from "src/routes/paths";

const AppRoutesBased = () => {
  const { isUser } = useAuth();

  const guestRoutes = useRef(useAuthRoutes()).current;
  const routes = useRef(getListRoute()).current;
  return (
    <RouterGenerator
      userRoutes={routes}
      guestRoutes={guestRoutes}
      initialGuestRoutePath={routePaths.Auth.Index}
      initialRoutePath={routePaths.Auth.Index}
      initialUserRoutePath={routePaths.Screen.Index}
      isUser={isUser}
    />
  );
};

export const AppRoutes = memo(AppRoutesBased);
