import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { directorysRepository } from "src/repositories/DirectorysRepository";
import {
  PostArchives,
  ArchivesListRequest,
  getTableArchives,
  PostProfileArchives,
  ArchivesEditRequest,
} from "src/models/archives";
import { DeleteRequest } from "src/models/Request";

const ArcchivesModule = createReduxObservableModule(
  {
    getAllSelect: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<undefined>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap(() =>
            directorysRepository.getAllSelect().pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    getOrganizationalUnits: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<undefined>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap(() =>
            directorysRepository.getOrganizationalUnits().pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    addNewArchives: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<PostArchives>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            directorysRepository.addNewArchives(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    editTableRowArchives: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<ArchivesListRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            directorysRepository.editTableRowArchivesType(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    deleteTableRowArchives: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<DeleteRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            directorysRepository.deleteTableRowArchives(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    getTableRowArchives: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<getTableArchives>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            directorysRepository.getTableRowArchives(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    addNewProfileArchives: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<PostProfileArchives>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            directorysRepository.addNewFrofileArchives(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    deleteProfileArchives: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<DeleteRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            directorysRepository.deleteProfileArchives(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    editProfileArchivesType: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<ArchivesEditRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            directorysRepository.editProfileArchivesType(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    getArchivesSelect: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<undefined>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap(() =>
            directorysRepository.getArchivesSelect().pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
  },
  "ARCHIVES"
);
export default ArcchivesModule;
