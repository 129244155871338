import { combineReducers } from "redux";
import authModule from "src/redux/modules/auth";
import directorysModule from "src/redux/modules/directorys";
import administrationModule from "src/redux/modules/administration";
import archivesModule from "src/redux/modules/archives";
import accountModule from "src/redux/modules/account";
import profileSearchModule from "src/redux/modules/advancedSearch";
import preservationOfRecordModule from "src/redux/modules/preservationOfRecord";
import reportModule from "src/redux/modules/report";
import profileModule from "src/redux/modules/profile";
import documentModule from "src/redux/modules/document";
import phongsModule from "src/redux/modules/phongs";
import dashboardModule from "src/redux/modules/dashboard";
import organizationUnitsModule from "src/redux/modules/organizationUnits";
import roleModule from "src/redux/modules/role";
import readersModule from "src/redux/modules/readers";
import circulationDocumentModule from "src/redux/modules/circulationDocument";
import contractModule from "src/redux/modules/contract";
import dataRequestFormModule from "src/redux/modules/dataRequestForm";

export const rootReducer = combineReducers({
  auth: authModule.reducer,
  account: accountModule.reducer,
  directory: directorysModule.reducer,
  administration: administrationModule.reducer,
  archives: archivesModule.reducer,
  prifileSearch: profileSearchModule.reducer,
  preservationOfRecord: preservationOfRecordModule.reducer,
  report: reportModule.reducer,
  profileModule: profileModule.reducer,
  document: documentModule.reducer,
  phongsModule: phongsModule.reducer,
  dashboard: dashboardModule.reducer,
  organizationUnitsModule: organizationUnitsModule.reducer,
  roleModule: roleModule.reducer,
  readers: readersModule.reducer,
  circulationDocument: circulationDocumentModule.reducer,
  contract: contractModule.reducer,
  dataRequestForm: dataRequestFormModule.reducer,
});
