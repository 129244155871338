import { ofType } from "redux-observable";
import { of } from "rxjs";
import { catchError, map, mergeMap, takeUntil } from "rxjs/operators";
import { Action } from "src/core/models/redux";
import {
  createReduxObservableModule,
  ReduxObservableModuleEpicProps,
} from "src/core/redux/ReduxObservableModule";
import { AllParentFieldsRequest } from "src/models/directorys/directory";
import {
  AllDocumentsSearch,
  AllFontsSearch,
  AllProfileSearch,
  AllReaderSearch,
} from "src/models/profileSearch";
import { profileSearchRepository } from "src/repositories/AdvancedSearchRepository";
import { directorysRepository } from "src/repositories/DirectorysRepository";

const ProfileSreachModule = createReduxObservableModule(
  {
    getFields: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<AllParentFieldsRequest>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            directorysRepository.getParentFields(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
    getAllProfileSearch: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<AllProfileSearch>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            profileSearchRepository.getProfileSearch(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    exportProfileSearch: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<AllProfileSearch>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          profileSearchRepository.exportProfileSearch(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    getAllDocumentsSearch: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<AllDocumentsSearch>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            profileSearchRepository.getDocumentsSearch(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    exportDocumentsSearch: ({
      actions$,
      actions,
      actionTypes,
    }: ReduxObservableModuleEpicProps<Action<AllDocumentsSearch>>) =>
      actions$.pipe(
        ofType(actionTypes.start),
        mergeMap((action) =>
          profileSearchRepository.exportDocumentsSearch(action.payload).pipe(
            map((response) => actions.success(response)),
            catchError((error) => of(actions.failed(error)))
          )
        )
      ),

    getAllFontsSearch: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<AllFontsSearch>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            profileSearchRepository.getFontsSearch(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },

    getAllReaderSearch: {
      epic: ({
        actions$,
        actionTypes,
        actions,
      }: ReduxObservableModuleEpicProps<Action<AllReaderSearch>>) =>
        actions$.pipe(
          ofType(actionTypes.start),
          mergeMap((action) =>
            profileSearchRepository.getReaderSearch(action.payload).pipe(
              map((response) => actions.success(response.data)),
              catchError((error) => {
                return of(actions.failed(error));
              }),
              takeUntil(actions$.pipe(ofType(actionTypes.cancelled)))
            )
          )
        ),
    },
  },
  "PROFILESEARCH"
);
export default ProfileSreachModule;
