import { lazy } from "react";
import { useTranslation } from "react-i18next";
import Images from "src/assets/images";
import Permissions from "src/constants/permessions";
import { MenuItem } from "src/models/layout";
import routePaths from "src/routes/paths";
import usePreservationOfRecordRoutes from "src/routes/preservationOfRecordRoutes";

const Icon = ({ src }: { src: string }) => (
  <img
    src={src}
    style={{ width: 30, height: 30, paddingRight: 10, objectFit: "contain" }}
  />
);

const useScreenRoutes = (): MenuItem[] => {
  const { t } = useTranslation("pages");
  const preservationOfRecordRoutes = usePreservationOfRecordRoutes();

  return [
    {
      name: t("dashboaed.menu_name"),
      path: routePaths.Screen.Index,
      icon: <Icon src={Images.menu.menuDashborad} />,
      component: lazy(() => import("src/pages/screen/Dashboard")),
      permissions: [
        Permissions.Permission.DashBoard.SettingPreservationOfRecordByArchives,
        Permissions.Permission.DashBoard.SettingPreservationOfRecordByField,
      ],
    },
    {
      name: t("search.menu_name"),
      path: routePaths.Screen.Search.Index,
      icon: <Icon src={Images.menu.menuSearch} />,
      routes: [
        {
          path: routePaths.Screen.Search.Profile.Index,
          name: t("search.index.profile"),
          hideChildrenInMenu: true,
          permissions: [
            Permissions.Permission.AdvanceSearchManage
              .SearchPreservationOfRecord.Index,
          ],
          routes: [
            {
              path: routePaths.Screen.Search.Profile.Index,
              name: t("search.index.profile"),
              component: lazy(
                () => import("src/pages/screen/Searching/ProfileSearch")
              ),
              permissions: [
                Permissions.Permission.AdvanceSearchManage
                  .SearchPreservationOfRecord.View,
                Permissions.Permission.AdvanceSearchManage
                  .SearchPreservationOfRecord.ViewList,
              ],
            },
            {
              name: t("preservation_of_record.detail.page_title", {
                action: "Chỉnh sửa",
              }),
              path: routePaths.Screen.Search.Profile.DetailsProfile,
              component: lazy(
                () => import("src/pages/screen/PreservationOfRecord/Create")
              ),
            },
          ],
        },
        {
          path: routePaths.Screen.Search.Text.Index,
          name: t("search.index.Text"),
          hideChildrenInMenu: true,
          permissions: [
            Permissions.Permission.AdvanceSearchManage.SearchDocument.Index,
          ],
          routes: [
            {
              path: routePaths.Screen.Search.Text.Index,
              name: t("search.index.Text"),
              component: lazy(
                () => import("src/pages/screen/Searching/DocumentsSearch")
              ),
              permissions: [
                Permissions.Permission.AdvanceSearchManage.SearchDocument.View,
                Permissions.Permission.AdvanceSearchManage.SearchDocument
                  .ViewList,
              ],
            },
            {
              name: t("preservation_of_record.document.view"),
              path: routePaths.Screen.Search.Text.DetailsDocument,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/PreservationOfRecord/Create/Document/View"
                  )
              ),
            },
          ],
        },
        {
          path: routePaths.Screen.Search.Fonts.Index,
          name: t("search.index.Fonts"),
          hideChildrenInMenu: true,
          permissions: [
            Permissions.Permission.AdvanceSearchManage.SearchPhong.Index,
          ],
          routes: [
            {
              path: routePaths.Screen.Search.Fonts.Index,
              name: t("search.index.Fonts"),
              component: lazy(
                () => import("src/pages/screen/Searching/FontsSearch")
              ),
              permissions: [
                Permissions.Permission.AdvanceSearchManage.SearchPhong.View,
                Permissions.Permission.AdvanceSearchManage.SearchPhong.ViewList,
              ],
            },
            {
              name: "Thông tin chi tiết phông",
              path: routePaths.Screen.Search.Fonts.DetailsFonts,
              component: lazy(
                () => import("src/pages/screen/Phongs/detailsPhongs")
              ),
            },
          ],
        },
        {
          path: routePaths.Screen.Search.Reader.Index,
          name: t("search.index.Reader"),
          hideChildrenInMenu: true,
          permissions: [
            Permissions.Permission.AdvanceSearchManage.SearchTheReader.Index,
          ],
          routes: [
            {
              path: routePaths.Screen.Search.Reader.Index,
              name: t("search.index.Reader"),
              component: lazy(
                () => import("src/pages/screen/Searching/ReaderSearch")
              ),
              permissions: [
                Permissions.Permission.AdvanceSearchManage.SearchTheReader.View,
                Permissions.Permission.AdvanceSearchManage.SearchTheReader
                  .ViewList,
              ],
            },
            {
              name: t("readers.registration_list.edit.page_title"),
              path: routePaths.Screen.Search.Reader.RegistrationDetails.Index,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RegistrationList/Add"
                  )
              ),
            },
            {
              name: t("readers.request_info.add.page_title"),
              path: routePaths.Screen.Search.Reader.RequestDatails.Index,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RequestInformation/Add"
                  )
              ),
            },
            {
              path: routePaths.Screen.Search.Reader.ProvideDetails.Index,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/ProvideData/Create"
                  )
              ),
              permissions: [
                Permissions.Permission.TheReader.Edit,
                Permissions.Permission.TheReader.View,
              ],
            },
          ],
        },
      ],
    },
    {
      name: t("preservation_of_record.menu_name"),
      path: routePaths.Screen.PreservationOfRecord.Index,
      icon: <Icon src={Images.menu.menuFile} />,
      permissions: [
        Permissions.Permission.PreservationOfRecord.ViewList,
        Permissions.Permission.PreservationOfRecord.View,
        Permissions.Permission.PreservationOfRecord.Create,
        Permissions.Permission.PreservationOfRecord.Edit,
        Permissions.Permission.CirculationDocument.ViewList,
        Permissions.Permission.CirculationDocument.Create,
        Permissions.Permission.CirculationDocument.View,
      ],
      routes: [
        ...preservationOfRecordRoutes,
        {
          name: t("preservation_of_record.document.view"),
          path: routePaths.Screen.PreservationOfRecord.DocumentDetail,
          hideInMenu: true,
          component: lazy(
            () =>
              import(
                "src/pages/screen/PreservationOfRecord/Create/Document/View"
              )
          ),
          permissions: [Permissions.Permission.Document.View],
        },
      ],
    },
    {
      // phongs
      name: t("storage.menu_name"),
      path: routePaths.Screen.Phongs.Index,
      permissions: Permissions.Permission.Phongs.ViewList,
      icon: <Icon src={Images.menu.menuFont} />,
      hideChildrenInMenu: true,
      routes: [
        {
          name: t("storage.menu_name"),
          path: routePaths.Screen.Phongs.Index,
          component: lazy(() => import("src/pages/screen/Phongs")),
          permissions: [
            Permissions.Permission.Phongs.ViewList,
            Permissions.Permission.Phongs.Create,
            Permissions.Permission.Phongs.Edit,
            Permissions.Permission.Phongs.Delete,
            Permissions.Permission.Phongs.View,
          ],
        },
        {
          name: "Sửa phông",
          path: routePaths.Screen.Phongs.Edit,
          component: lazy(() => import("src/pages/screen/Phongs/editPhongs")),
          permissions: Permissions.Permission.Phongs.Edit,
        },
        {
          name: "Thêm mới phông",
          path: routePaths.Screen.Phongs.Add,
          component: lazy(() => import("src/pages/screen/Phongs/addPhongs")),
          permissions: Permissions.Permission.Phongs.Create,
        },
        {
          name: "Thông tin chi tiết phông",
          path: routePaths.Screen.Phongs.Details,
          component: lazy(
            () => import("src/pages/screen/Phongs/detailsPhongs")
          ),
          permissions: Permissions.Permission.Phongs.View,
        },
      ],
    },
    {
      name: t("categories.menu_name"),
      path: routePaths.Screen.Categories.Index,
      icon: <Icon src={Images.menu.menuCategories} />,
      routes: [
        {
          path: routePaths.Screen.Categories.Index,
          name: t("categories.index"),
          component: lazy(
            () => import("src/pages/screen/Directorys/Directory")
          ),
          permissions: [
            Permissions.Permission.Category.Create,
            Permissions.Permission.Category.Edit,
            Permissions.Permission.Category.Delete,
            Permissions.Permission.Category.ViewList,
          ],
        },
        {
          path: routePaths.Screen.Categories.Warehouse,
          name: t("categories.warehouse"),
          hideChildrenInMenu: true,
          component: lazy(() => import("src/pages/screen/Directorys/Archives")),
          permissions: [
            Permissions.Permission.Archives.Create,
            Permissions.Permission.Archives.Delete,
            Permissions.Permission.Archives.Edit,
            Permissions.Permission.Archives.ViewList,
          ],
        },
      ],
    },
    // Readers Management
    {
      name: t("readers.menu_name"),
      path: routePaths.Screen.Readers.Index,
      icon: <Icon src={Images.menu.menuReaders} />,
      routes: [
        {
          name: t("readers.registration_list.menu_name"),
          path: routePaths.Screen.Readers.RegistrationList.Index,
          hideChildrenInMenu: true,
          permissions: [
            Permissions.Permission.TheReader.ViewList,
            Permissions.Permission.TheReader.View,
            Permissions.Permission.TheReader.Create,
            Permissions.Permission.TheReader.Edit,
            Permissions.Permission.TheReader.PrintView,
          ],
          routes: [
            {
              name: t("readers.registration_list.menu_name"),
              path: routePaths.Screen.Readers.RegistrationList.Index,
              component: lazy(
                () =>
                  import("src/pages/screen/ReadersManagement/RegistrationList")
              ),
              permissions: Permissions.Permission.TheReader.ViewList,
            },
            {
              name: t("readers.registration_list.add.page_title"),
              path: routePaths.Screen.Readers.RegistrationList.Add,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RegistrationList/Add"
                  )
              ),
              permissions: Permissions.Permission.TheReader.Create,
            },
            {
              name: t("readers.registration_list.edit.page_title"),
              path: routePaths.Screen.Readers.RegistrationList.Edit,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RegistrationList/Add"
                  )
              ),
              permissions: Permissions.Permission.TheReader.Edit,
            },
          ],
        },
        {
          name: t("readers.request_info.menu_name"),
          path: routePaths.Screen.Readers.RequestInformation.Index,
          hideChildrenInMenu: true,
          permissions: [
            Permissions.Permission.TheReader.ViewList,
            Permissions.Permission.TheReader.View,
            Permissions.Permission.TheReader.Create,
            Permissions.Permission.TheReader.Edit,
            Permissions.Permission.TheReader.PrintView,
          ],
          routes: [
            {
              name: t("readers.request_info.menu_name"),
              path: routePaths.Screen.Readers.RequestInformation.Index,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RequestInformation"
                  )
              ),
              permissions: Permissions.Permission.TheReader.ViewList,
            },
            {
              name: t("readers.request_info.add.page_title"),
              path: routePaths.Screen.Readers.RequestInformation.Add,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RequestInformation/Add"
                  )
              ),
              permissions: Permissions.Permission.TheReader.Create,
            },
            {
              name: t("readers.request_info.add.page_title"),
              path: routePaths.Screen.Readers.RequestInformation.Edit,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/RequestInformation/Add"
                  )
              ),
              permissions: Permissions.Permission.TheReader.Edit,
            },
          ],
        },
        {
          name: t("readers.provide_data.menu_name"),
          path: routePaths.Screen.Readers.ProvideData.Index,
          hideChildrenInMenu: true,
          permissions: [
            Permissions.Permission.TheReader.ViewList,
            Permissions.Permission.TheReader.Edit,
            Permissions.Permission.TheReader.Create,
            Permissions.Permission.TheReader.View,
          ],
          routes: [
            {
              name: t("readers.provide_data.menu_name"),
              path: routePaths.Screen.Readers.ProvideData.Index,
              component: lazy(
                () => import("src/pages/screen/ReadersManagement/ProvideData")
              ),
              permissions: [Permissions.Permission.TheReader.ViewList],
            },
            {
              path: routePaths.Screen.Readers.ProvideData.Create,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/ProvideData/Create"
                  )
              ),
              permissions: [Permissions.Permission.TheReader.Create],
            },
            {
              path: routePaths.Screen.Readers.ProvideData.Detail,
              component: lazy(
                () =>
                  import(
                    "src/pages/screen/ReadersManagement/ProvideData/Create"
                  )
              ),
              permissions: [
                Permissions.Permission.TheReader.Edit,
                Permissions.Permission.TheReader.View,
              ],
            },
          ],
        },
      ],
    },
    // Report
    {
      name: t("report.menu_name"),
      path: routePaths.Screen.Report.Index,
      icon: <Icon src={Images.menu.menuReport} />,
      permissions: [
        Permissions.Permission.StatisticAndReport.ArchiveWorkReport.Download,
        Permissions.Permission.StatisticAndReport.ArchiveWorkReport
          .ViewAndPrint,
        Permissions.Permission.StatisticAndReport.StatisticListOfStorageStatus,
      ],
      routes: [
        {
          name: t("report.storage_status.page_title"),
          path: routePaths.Screen.Report.StorageStatus.Index,
          component: lazy(
            () => import("src/pages/screen/Report/StorageStatus")
          ),
          permissions:
            Permissions.Permission.StatisticAndReport
              .StatisticListOfStorageStatus,
        },
        {
          name: t("report.storage_work.page_title"),
          path: routePaths.Screen.Report.StorageWork.Index,
          component: lazy(() => import("src/pages/screen/Report/StorageWork")),
          permissions: [
            Permissions.Permission.StatisticAndReport.ArchiveWorkReport
              .Download,
            Permissions.Permission.StatisticAndReport.ArchiveWorkReport
              .ViewAndPrint,
          ],
        },
      ],
    },
    // Administration
    {
      name: t("administer.menu_name"),
      path: routePaths.Screen.Administration.Index,
      icon: <Icon src={Images.menu.menuAdministration} />,
      routes: [
        {
          name: t("administer.index.page_title"),
          path: routePaths.Screen.Administration.OrganizationUnits.Index,
          component: lazy(
            () => import("src/pages/screen/Administration/OrganizationUnits")
          ),
          permissions: [
            Permissions.Permission.OrganizationUnit.Create,
            Permissions.Permission.OrganizationUnit.Delete,
            Permissions.Permission.OrganizationUnit.Active,
            Permissions.Permission.OrganizationUnit.Edit,
            Permissions.Permission.OrganizationUnit.ViewList,
            Permissions.Permission.OrganizationUnit.View,
          ],
        },
        {
          name: t("administer.role.menu_name"),
          path: routePaths.Screen.Administration.Role.Index,
          component: lazy(() => import("src/pages/screen/Administration/Role")),
          permissions: [
            Permissions.Permission.RoleManage.Create,
            Permissions.Permission.RoleManage.Delete,
            Permissions.Permission.RoleManage.Edit,
            Permissions.Permission.RoleManage.View,
            Permissions.Permission.RoleManage.ViewList,
          ],
        },
        {
          name: t("administer.user.menu_name"),
          path: routePaths.Screen.Administration.User.Index,
          component: lazy(() => import("src/pages/screen/Administration/User")),
          permissions: [
            Permissions.Auth.UserManage.Create,
            Permissions.Auth.UserManage.Export,
            Permissions.Auth.UserManage.View,
            Permissions.Auth.UserManage.ViewList,
            Permissions.Auth.UserManage.LockAndManage,
            Permissions.Auth.UpdateAvartar,
            Permissions.Auth.UserManageEdit,
            Permissions.Auth.UserManageDelete,
            Permissions.Auth.UpdateProfile,
            Permissions.Auth.ChangePassword,
          ],
        },
      ],
    },
    {
      path: routePaths.Screen.Index,
      hideInMenu: true,
      redirect: routePaths.Screen.User.Index,
      exact: true,
    },
  ];
};

export default useScreenRoutes;
