import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AuthState } from "src/models/auth/authState";
import { RootReducer } from "src/redux";
import authModule from "src/redux/modules/auth";

export default function useAuth() {
  const dispatch = useDispatch();
  const authReducer = useSelector((rootState: RootReducer) => rootState.auth);

  const isUser = useMemo(() => {
    return authReducer.state === AuthState.loggedIn;
  }, [authReducer.state]);

  const fetchUserProfile = useCallback(() => {
    dispatch(authModule.actions.fetchUser.start());
  }, []);

  const logout = useCallback(() => {
    dispatch(authModule.actions.logout.start());
  }, []);

  return {
    ...authReducer.data,
    fetchUserProfile,
    state: authReducer.state as AuthState,
    isUser,
    logout,
  };
}
