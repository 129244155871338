const Permissions = {
  Auth: {
    RoleManage: "Auth.RoleManage",

    UpdateAvartar: "Auth.UpdateAvartar",
    UpdateProfile: "Auth.UpdateProfile",

    ChangePassword: "Auth.ChangePassword",
    UserManageDelete: "Auth.UserManageDelete",
    UserManageEdit: "Auth.UserManageEdit",
    // Quản lý người dùng
    UserManage: {
      Create: "Auth.UserManage.Create",
      ViewList: "Auth.UserManage.ViewList",
      LockAndManage: "Auth.UserManage.LockAndManage",
      Export: "Auth.UserManage.Export",
      ResetPassword: "Auth.UserManage.ResetPassword",
      View: "Auth.UserManage.View",
    },
    ProfileManage: {
      Index: "Auth.ProfileManage",
      View: "Auth.ProfileManage.View",
      Edit: "Auth.ProfileManage.Edit",
    },
  },
  Permission: {
    Phongs: {
      Edit: "Permission.Phong.Edit",
      ViewList: "Permission.Phong.ViewList",
      Delete: "Permission.Phong.Delete",
      Create: "Permission.Phong.Create",
      View: "Permission.Phong.View",
    },
    // Báo cáo, thống kê
    StatisticAndReport: {
      ArchiveWorkReport: {
        Download: "Permission.StatisticAndReport.ArchiveWorkReport.Download",
        ViewAndPrint:
          "Permission.StatisticAndReport.ArchiveWorkReport.ViewAndPrint",
      },
      StatisticListOfStorageStatus:
        "Permission.StatisticAndReport.StatisticListOfStorageStatus",
    },
    // cơ cấu tổ chức
    OrganizationUnit: {
      Create: "Permission.OrganizationUnit.Create",
      Edit: "Permission.OrganizationUnit.Edit",
      Active: "Permission.OrganizationUnit.Lock/Active",
      View: "Permission.OrganizationUnit.View",
      Delete: "Permission.OrganizationUnit.Delete",
      ViewList: "Permission.OrganizationUnit.ViewList",
    },
    AdvanceSearchManage: {
      SearchPreservationOfRecord: {
        Index: "Permission.AdvanceSearchManage.SearchPreservationOfRecord",
        View: "Permission.AdvanceSearchManage.SearchPreservationOfRecord.View",
        ViewList:
          "Permission.AdvanceSearchManage.SearchPreservationOfRecord.ViewList",
      },
      Index: "Permission.AdvanceSearchManage",
      SearchTheReader: {
        Index: "Permission.AdvanceSearchManage.SearchTheReader",
        View: "Permission.AdvanceSearchManage.SearchTheReader.View",
        ViewList: "Permission.AdvanceSearchManage.SearchTheReader.ViewList",
      },
      SearchDocument: {
        Index: "Permission.AdvanceSearchManage.SearchDocument",
        View: "Permission.AdvanceSearchManage.SearchDocument.View",
        ViewList: "Permission.AdvanceSearchManage.SearchDocument.ViewList",
      },
      SearchPhong: {
        Index: "Permission.AdvanceSearchManage.SearchPhong",
        View: "Permission.AdvanceSearchManage.SearchPhong.View",
        ViewList: "Permission.AdvanceSearchManage.SearchPhong.ViewList",
      },
    },
    // Quản lý văn bản
    Document: {
      DownloadFile: "Permission.Document.DownloadFile",
      View: "Permission.Document.View",
      Edit: "Permission.Document.Edit",
      Print: "Permission.Document.Print",
      ImportFile: "Permission.Document.ImportFile",
      DownloadTempFile: "Permission.Document.DownloadTempFile",
      Delete: "Permission.Document.Delete",
      Create: "Permission.Document.Create",
      ViewList: "Permission.Document.ViewList",
      Index: "Permission.Document",
    },
    // Quản lý độc giả
    TheReader: {
      Create: "Permission.TheReader.Create",
      Export: "Permission.TheReader.Export",
      Delete: "Permission.TheReader.Delete",
      ViewList: "Permission.TheReader.ViewList",
      Edit: "Permission.TheReader.Edit",
      Print: "Permission.TheReader.Print",
      View: "Permission.TheReader.View",
      PrintView: "Permission.TheReader.PrintView",
    },
    // Dashboard
    DashBoard: {
      SettingPreservationOfRecordByField:
        "Permission.DashBoard.SettingPreservationOfRecordByField",
      SettingPreservationOfRecordByArchives:
        "Permission.DashBoard.SettingPreservationOfRecordByArchives",
      Index: "Permission.DashBoard",
    },
    // Quản lý lưu chuyển văn bản
    CirculationDocument: {
      DownloadFile: "Permission.CirculationDocument.DownloadFile",
      View: "Permission.CirculationDocument.View",
      ViewList: "Permission.CirculationDocument.ViewList",
      Create: "Permission.CirculationDocument.Create",
      Delete: "Permission.CirculationDocument.Delete",
      Index: "Permission.CirculationDocument",
    },
    // danh mục
    Category: {
      Delete: "Permission.Category.Delete",
      Edit: "Permission.Category.Edit",
      Create: "Permission.Category.Create",
      ViewList: "Permission.Category.ViewList",
    },
    // Quản lý kho
    Archives: {
      ViewList: "Permission.Archives.ViewList",
      Create: "Permission.Archives.Create",
      Delete: "Permission.Archives.Delete",
      Edit: "Permission.Archives.Edit",
    },
    // vai trò
    RoleManage: {
      Create: "Auth.RoleManage.Create",
      View: "Auth.RoleManage.View",
      ViewList: "Auth.RoleManage.ViewList",
      Delete: "Auth.RoleManage.Delete",
      Edit: "Auth.RoleManage.Edit",
    },
    // Quản lý hồ sơ
    PreservationOfRecord: {
      Print: "Permission.PreservationOfRecord.Print",
      View: "Permission.PreservationOfRecord.View",
      Edit: "Permission.PreservationOfRecord.Edit",
      DownloadTempFile: "Permission.PreservationOfRecord.DownloadTempFile",
      ViewList: "Permission.PreservationOfRecord.ViewList",
      DownloadFile: "Permission.PreservationOfRecord.DownloadFile",
      Create: "Permission.PreservationOfRecord.Create",
      ImportFile: "Permission.PreservationOfRecord.ImportFile",
      Delete: "Permission.PreservationOfRecord.Delete",
      Index: "Permission.PreservationOfRecord",
    },
  },
};

export default Permissions;
