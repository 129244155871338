import { useState } from "react";
import { Typography, Button, Upload, message } from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { RcFile, UploadFile } from "antd/lib/upload/interface";
import { UploadChangeParam } from "antd/lib/upload";
import { Card } from "src/components";
const { Dragger } = Upload;

interface UploadPhotoProps {
  action: string;
  name?: string;
  title?: string;
}

function getBase64(
  img: Blob,
  callback: (r: string | ArrayBuffer | null) => void
) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file: RcFile) {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
}

const UploadPhoto = ({
  name = "avatar",
  action,
  title,
}: UploadPhotoProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setURL] = useState<any>(null);

  const onChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      getBase64(info.file.originFileObj as Blob, (imageUrl) => {
        setURL(imageUrl);
        setLoading(false);
      });
    }
  };

  const uploadButton = (
    <div>
      {loading ? (
        <LoadingOutlined />
      ) : (
        <div className="d-flex py-3 flex-column align-items-center">
          <Button icon={<UploadOutlined />}>Thêm ảnh</Button>
          <Typography.Text className="mt-2">
            Hoặc kéo thả ảnh để thêm
          </Typography.Text>
        </div>
      )}
    </div>
  );

  const props = {
    name,
    className: "avatar-uploader",
    showUploadList: false,
    action,
    beforeUpload,
    onChange,
  };

  return (
    <Card title={title} className="mb-3">
      <Dragger {...props} className="bg-white">
        {imageUrl ? <img src={imageUrl} alt="avatar" /> : uploadButton}
      </Dragger>
    </Card>
  );
};

export default UploadPhoto;
