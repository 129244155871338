import { LeftOutlined } from "@ant-design/icons";
import { Layout, PageHeader, Spin } from "antd";
import {
  createContext,
  FunctionComponent,
  ReactNode,
  Suspense,
  useCallback,
  useLayoutEffect,
  useState,
} from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useHistory } from "react-router-dom";
import { Button } from "src/components";
import env from "src/core/env";
import { useToggle } from "src/core/hooks";
import { Listener } from "src/core/utilities/EventListenersManager";
import useCurrentRoute from "src/hooks/useCurrentRoute";
import LayoutHeader from "src/layouts/Component/Header";
import { BasedMenu } from "src/layouts/Dashboard/components/BasedMenu";
import LayoutEventsListenersManager, {
  LayoutEvents,
} from "src/layouts/LayoutEventsListenersManager";
import { MenuItem } from "src/models/layout";

const { Content, Sider } = Layout;

interface DashboardLayoutProps {
  defaultConfig?: LayoutConfig;
  routes: MenuItem[];
  bottomRoutes: MenuItem[];
}

export interface LayoutConfig {
  showPageTitle?: boolean;
  showHeaderExtra?: boolean;
  headerExtra?: ReactNode;
  backButton?: string | boolean;
  showSidebar?: boolean;
  pageTitle?: string;
}

export type LayoutConfigContextType = {
  config: LayoutConfig;
  addEventListener<E extends keyof LayoutEvents>(
    event: E,
    listener: Listener<LayoutEvents[E]>
  ): () => void;
  changeLayoutConfig: (config: LayoutConfig) => void;
};

export const LayoutConfigContext = createContext<
  LayoutConfigContextType | undefined
>(undefined);

const defaultLayoutConfig: LayoutConfig = {
  showPageTitle: true,
  showHeaderExtra: true,
  headerExtra: undefined,
  showSidebar: true,
  backButton: false,
  pageTitle: "",
};

const DashboardLayout: FunctionComponent<DashboardLayoutProps> = ({
  defaultConfig = defaultLayoutConfig,
  children,
  routes,
  // bottomRoutes,
}) => {
  // Handle layout config
  const { location } = useHistory();
  const [config, setConfig] = useState<LayoutConfig>(defaultConfig);

  const changeLayoutConfig = useCallback((config: LayoutConfig) => {
    setConfig((old) => ({
      ...old,
      ...config,
    }));
  }, []);

  const addEventListener = useCallback(function <E extends keyof LayoutEvents>(
    event: E,
    listener: Listener<LayoutEvents[E]>
  ) {
    return LayoutEventsListenersManager.on(event, listener);
  },
  []);

  useLayoutEffect(() => {
    setConfig(defaultConfig);
  }, [location.pathname]);

  // Handle page title and breadcrumb
  const currentRoute = useCurrentRoute(routes);
  useLayoutEffect(() => {
    if (currentRoute) {
      document.title =
        currentRoute.location?.key ||
        currentRoute.documentTitle ||
        currentRoute.pageTitle ||
        currentRoute.name ||
        env.APP_NAME;

      if (!config.pageTitle) {
        changeLayoutConfig({
          pageTitle: currentRoute.pageTitle || currentRoute.name,
        });
      }
    }
  }, [currentRoute]);

  // const fetchAllFields = async (): Promise<any> => {
  //   const response = await administrationRepository.getProfile().toPromise();
  //   setdata(response.data);
  // };

  // Handle second sider collapse
  const {
    state: secondSiderCollapsed,
    on: collapseSecondSider,
    off: expandSecondSider,
    toggle: toggleSeconSiderCollapse,
  } = useToggle();

  const handleSecondSiderBreak = useCallback((breaked: boolean) => {
    if (breaked) {
      collapseSecondSider();
    } else {
      expandSecondSider();
    }
  }, []);

  // Handle back button click
  const history = useHistory();
  const handleBackButtonClick = useCallback(() => {
    if (config.backButton !== false) {
      typeof config.backButton === "string" && config.backButton.length > 0
        ? history.push(config.backButton)
        : history.goBack();
    }
  }, [config.backButton]);
  return (
    <LayoutConfigContext.Provider
      value={{
        config,
        changeLayoutConfig,
        addEventListener,
      }}
    >
      <PerfectScrollbar
        options={{
          wheelSpeed: 0.5,
          suppressScrollX: true,
        }}
      >
        <Layout id="dashboard-layout" className="fixed-header">
          <LayoutHeader />
          <Layout>
            <Sider
              className="second-sider pb-3"
              breakpoint="lg"
              onBreakpoint={handleSecondSiderBreak}
              collapsed={secondSiderCollapsed}
              trigger={
                <Button
                  icon={
                    <LeftOutlined rotate={secondSiderCollapsed ? 180 : 0} />
                  }
                  className="m-n1"
                  type="primary"
                  size="large"
                  onClick={toggleSeconSiderCollapse}
                />
              }
              collapsible
            >
              <BasedMenu mode="inline" items={routes} />
              {/* <BasedMenu mode="inline" items={bottomRoutes} /> */}
            </Sider>
            <Layout className="right-layout">
              <Suspense
                fallback={
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Spin spinning />
                  </div>
                }
              >
                {!!currentRoute && (
                  <div className="page-header-container">
                    <div className="container">
                      <PageHeader
                        backIcon={
                          <LeftOutlined
                            className="p-2 border rounded"
                            style={{ color: "#999" }}
                          />
                        }
                        onBack={
                          config.backButton !== false
                            ? handleBackButtonClick
                            : undefined
                        }
                        title={
                          config.showPageTitle ? config.pageTitle : undefined
                        }
                        extra={config.showHeaderExtra && config.headerExtra}
                        className="py-0"
                      />
                    </div>
                  </div>
                )}
                <div className="container d-flex flex-column">
                  <Content className="p-4">{children}</Content>
                </div>
              </Suspense>
            </Layout>
          </Layout>
        </Layout>
      </PerfectScrollbar>
    </LayoutConfigContext.Provider>
  );
};

export default DashboardLayout;
