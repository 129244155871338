import { Avatar, Col, Drawer, Layout, Typography } from "antd";
import { useEffect, useState } from "react";
import Images from "src/assets/images";
import Logo from "src/assets/images/logo.png";
import { Button, Row } from "src/components";
import Permissions from "src/constants/permessions";
import { PrivateView } from "src/core/permission/PrivateView";
import useAuth from "src/hooks/modules/useAuth";
import UserProfileDrawer from "src/layouts/Component/Header/UserProfileDrawer";
import { getSource } from "src/models/Status";
import routePaths from "src/routes/paths";
import "src/styles/auth/logout.less";

const { Header } = Layout;

const LayoutHeader = () => {
  const { profile, fetchUserProfile } = useAuth();
  const [visible, setVisible] = useState(false);
  const [subvisible, setSubVisible] = useState(false);
  const [loading, setloading] = useState(false);

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        fetchUserProfile();
      }, 500);
    } else {
      fetchUserProfile();
    }
    setloading(false);
  }, [loading]);

  const logOut = () => {
    localStorage.clear();
    window.location.href = routePaths.Auth.Index;
  };

  const checkOf = (i: boolean) => {
    setSubVisible(i);
  };

  const checkNewLoad = (value: boolean) => {
    setloading(value);
  };

  const checkNewAvatar = (values: any) => {
    //  setloading(value);
  };

  const subDrawer = () => {
    return (
      <Drawer
        title="Xem thông tin nhân viên"
        placement="right"
        closable={false}
        onClose={() => setSubVisible(false)}
        visible={subvisible}
        contentWrapperStyle={{
          top: "64px",
          height: 600,
          borderRadius: 10,
          width: 450,
        }}
      >
        <UserProfileDrawer
          info={profile}
          checkOf={checkOf}
          checkNewLoad={checkNewLoad}
          checkNewAvatar={checkNewAvatar}
        />
      </Drawer>
    );
  };

  return (
    <>
      <Header className="site-layout-background">
        <Row
          justify="space-between"
          className="align-items-center header-row-content"
        >
          <Col>
            <div className="d-flex flex-row justify-content-center align-items-center">
              <Avatar size={60} src={Logo} />
              <Typography.Title level={4} className="px-3 pt-2 sm-title">
                Quản lý tài liệu lưu trữ
              </Typography.Title>
            </div>
          </Col>
          <Col>
            <Row>
              <Col className="d-flex flex-row justify-content-center align-items-center">
                <Typography.Text type="secondary">Chào bạn,</Typography.Text>
                <Typography.Title
                  level={5}
                  className="title-spacing header-username"
                >
                  {profile?.account?.name ?? ""}
                </Typography.Title>
              </Col>
              <Col>
                <div
                  onClick={() => {
                    // fetchUserProfile();
                    setVisible(true);
                  }}
                  className="cursor-pointer"
                >
                  <Avatar size="large" src={getSource(profile?.account)} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Header>
      <Drawer
        visible={visible}
        onClose={() => setVisible(false)}
        placement="right"
        className="logout"
        closable={false}
        bodyStyle={{ background: "#E5F7F8", borderRadius: 10 }}
        drawerStyle={{ borderRadius: 10 }}
        style={{ borderRadius: 10 }}
        contentWrapperStyle={{
          top: "64px",
          height: 230,
        }}
      >
        <div>
          {profile?.account.fileExtension ? (
            <Avatar
              className="centerImage"
              shape="square"
              size={64}
              src={getSource(profile?.account)}
              style={{ backgroundColor: "var(--light-color)" }}
            />
          ) : (
            <>
              {profile?.account?.extraProperties?.Gender ? (
                <Avatar
                  className="centerImage"
                  shape="square"
                  src={Images.user.boy}
                  size={64}
                  style={{ backgroundColor: "var(--light-color)" }}
                  // className="p-1"
                />
              ) : (
                <Avatar
                  className="centerImage"
                  shape="square"
                  size={64}
                  src={Images.user.girl}
                  style={{ backgroundColor: "var(--light-color)" }}
                />
              )}
            </>
          )}
        </div>
        <div>
          <Typography.Title level={5} style={{ textAlign: "center" }}>
            {profile ? profile?.account?.name : ""}
          </Typography.Title>
          <PrivateView permissions={Permissions.Auth.ProfileManage.View}>
            <Button
              type="primary"
              style={{ background: "#34628f" }}
              className="centerButton"
              onClick={() => {
                setSubVisible(true);
                setVisible(false);
              }}
            >
              Trang cá nhân
            </Button>
          </PrivateView>
        </div>
        <div>
          <Button onClick={() => logOut()} className="centerButton">
            Đăng xuất
          </Button>
        </div>
      </Drawer>
      {subDrawer()}
    </>
  );
};

export default LayoutHeader;
