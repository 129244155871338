import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Badge,
  Col,
  Drawer,
  Form,
  Image,
  Input,
  message,
  Modal,
  Row,
  Space,
  Typography,
  Upload,
  UploadProps,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Images from "src/assets/images";
import { Button } from "src/components";
import Tags from "src/components/tags";
import Permissions from "src/constants/permessions";
import env from "src/core/env";
import { useDidUpdate, useLoading } from "src/core/hooks";
import { Action } from "src/core/models/redux";
import { PrivateView } from "src/core/permission/PrivateView";
import { ReduxObservableModuleReducer } from "src/core/redux/ReduxObservableModule";
import TokenManager from "src/core/utilities/TokenManager";
import UpdateProfile from "src/layouts/Component/Header/UpdateProfile";
import {
  AddUserRequest,
  ChangePasswordRequest,
} from "src/models/administration/User";
import { getSource, getUserStatus } from "src/models/Status";
import { RootReducer } from "src/redux";
import ArcchivesModule from "src/redux/modules/archives";
import profileModule from "src/redux/modules/profile";
import "src/styles/auth/logout.less";
import { getBase64 } from "src/utilities/imageHelper";

const { Title, Text } = Typography;

interface profileProps {
  archivesReducer: ReduxObservableModuleReducer<any>;
  profileReducer: ReduxObservableModuleReducer<any>;
  changePasswordScreen: (param: ChangePasswordRequest) => any;
  updateProfileScreen: (params: AddUserRequest) => any;
  getAllSelectScreen: () => any;
  info: any;
  checkOf: (param: boolean) => any;
  checkNewLoad: (param: boolean) => any;
  checkNewAvatar: (params: any) => any;
}

const UserProfileDrawer = (props: profileProps) => {
  const {
    info,
    checkOf,
    profileReducer,
    changePasswordScreen,
    updateProfileScreen,
    checkNewLoad,
    getAllSelectScreen,
    archivesReducer,
    checkNewAvatar,
  } = props;
  const { label, backgroundColor, color } = getUserStatus(
    info?.account.status || false
  );
  // const { can } = usePermissions;
  const [visible, setVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [listArchive, setArchives] = useState<any>([]);
  const [roles, setRoles] = useState<any>([]);
  const [isErrNew, setIsErrNew] = useState(false);
  const [contentErrNew, setContentErrNew] = useState("");
  const [isErr, setIsErr] = useState(false);
  const [contentErr, setContentErr] = useState("");
  const [isErrOld, setIsErrOld] = useState(false);
  const [contentErrOld, setContentErrOld] = useState("");
  const [filterArchive, setFilterArchive] = useState<any>({
    archivesInCharge: undefined,
    listArchiveLookup: [],
  });
  const { Item } = Form;
  const [form] = Form.useForm();
  const { startLoading, stopLoading } = useLoading();
  const datas: any = info?.account;
  const initialUserState = {
    userName: "",
    name: "",
    birthday: "",
    gender: "true",
    email: "",
    phoneNumber: "",
    identityCard: "",
    issueDate: "",
    placeOfIssue: "",
    province: null,
    district: null,
    address: "",
    roleNames: [],
    displayNameRoles: [],
    archivesInCharge: null,
    listArchiveLookup: [],
    publicId: "",
    fileExtension: "",
    password: "",
  };

  useEffect(() => {
    getAllSelectScreen();

    if (info.roles) {
      const rolesName: any[] = [];
      for (let i = 0; i < info.roles.length; i++) {
        rolesName.push(info.roles[i].roleDisplayName);
        setRoles(rolesName);
      }
    }
  }, []);

  useEffect(() => {
    if (
      info?.account.id &&
      info?.account.archivesInCharge &&
      listArchive.length > 0
    ) {
      const item = listArchive.find(
        (i: any) => i.archives.id === info?.account.archivesInCharge
      );
      setFilterArchive((prevState: any) => ({
        ...prevState,
        archivesInCharge: item?.archives.name,
      }));
    }
    if (
      info?.account.id &&
      typeof info?.account.listArchiveLookup === "object" &&
      info?.account.listArchiveLookup.length > 0
    ) {
      const listArchiveLookup: string[] = [];
      for (let i = 0; i <= listArchive.length; i++) {
        for (let j = 0; j <= info?.account.listArchiveLookup.length; j++) {
          if (
            info?.account.listArchiveLookup[j] ===
              listArchive[i]?.archives.id &&
            typeof listArchive[i]?.archives.name !== "undefined"
          ) {
            listArchiveLookup.push(listArchive[i]?.archives.name);
          }
        }
      }
      setFilterArchive((prevState: any) => ({
        ...prevState,
        listArchiveLookup: [...listArchiveLookup],
      }));
    }
  }, [info, listArchive]);

  useDidUpdate(() => {
    const { state, error } = archivesReducer;
    const {
      reducerStates: { getArchivesSelect },
    } = ArcchivesModule;
    const dataSource: any[] = [];
    switch (state) {
      case getArchivesSelect.success:
        setArchives(archivesReducer.data.items);
        break;
    }
  }, [archivesReducer]);

  useDidUpdate(() => {
    const { state, error } = profileReducer;
    const {
      reducerStates: { changePassword },
    } = profileModule;
    switch (state) {
      case changePassword.success:
        message.success("Đổi mật khẩu thành công");
        form.resetFields();
        setIsModalVisible(false);
        break;
      case changePassword.failed:
        if (
          error.response.data.error.code ===
          "Volo.Abp.Identity:PasswordMismatch"
        ) {
          setIsErrOld(true);
          setContentErrOld("Sai mật khẩu!");
        } else {
          setIsErr(true);
          setIsErrNew(true);
          setContentErrNew(
            "Mật khẩu phải có ít nhất 1 chữ hoa, 1 số và 1 ký tự đặc biệt!"
          );
        }
        break;
    }
  }, [profileReducer]);

  const [uploadInfo, setUploadInfo] = useState({
    fileExtension: "",
    publicId: "",
  });
  const [imageUrl, setImageUrl] = useState<string | ArrayBuffer | null>(null);
  const uploadProps: UploadProps = {
    name: "files",
    action: env.API_CDN_URL.replace(/['"]+/g, "") + "file/upload",
    showUploadList: false,
    headers: {
      authorization: "Bearer " + TokenManager.getToken("base_token"),
    },
    onChange(data) {
      if (data.file.status !== "uploading") {
        startLoading();
      }
      if (data.file.status === "done") {
        stopLoading();
        const fileExtension = data.file.response[0]?.fileExtension;
        const publicId = data.file.response[0]?.publicId;
        getBase64(
          data.file.originFileObj,
          (imageUrl: string | ArrayBuffer | null) => setImageUrl(imageUrl)
        );
        setUploadInfo({ fileExtension, publicId });
        const params: AddUserRequest = {
          ...initialUserState,
          ...datas,
          fileExtension,
          publicId,
        };
        delete params.displayNameRoles;
        updateProfileScreen(params);
        message.success(`Tải file thành công!`);
        checkNewAvatar(params);
        checkNewLoad(true);
      } else if (data.file.status === "error") {
        stopLoading();
        message.error(`Tải file thất bại.`);
      }
    },
  };

  const onFinish = (values: any) => {
    const checkNewPassword = values.checkNewPassword;
    const newPassword = values.newPassword;
    const currentPassword = values.currentPassword;
    if (
      checkNewPassword === "" ||
      newPassword === "" ||
      currentPassword === ""
    ) {
      switch ("") {
        case currentPassword:
          setIsErrOld(true);
          setContentErrOld("Vui lòng nhập mật khẩu");
          break;
        case newPassword:
          setIsErrNew(true);
          setContentErrNew("Vui lòng nhập mật khẩu");
          break;
        case checkNewPassword:
          setIsErr(true);
          setContentErr("Vui lòng nhập mật khẩu");
          break;
      }
    } else if (
      checkNewPassword === undefined ||
      newPassword === undefined ||
      currentPassword === undefined
    ) {
      switch (undefined) {
        case currentPassword:
          setIsErrOld(true);
          setContentErrOld("Vui lòng nhập mật khẩu");
          break;
        case newPassword:
          setIsErrNew(true);
          setContentErrNew("Vui lòng nhập mật khẩu");
          break;
        case checkNewPassword:
          setIsErr(true);
          setContentErr("Vui lòng nhập mật khẩu");
          break;
      }
    } else {
      if (values.newPassword.length < 6 || values.newPassword.length > 50) {
        setIsErrNew(true);
        setContentErrNew(
          "Mật khẩu không được ít hơn 6 ký tự và nhiều hơn 50 ký tự"
        );
      } else {
        if (values.newPassword === values.checkNewPassword) {
          delete values.checkNewPassword;
          changePasswordScreen(values);
        } else {
          setIsErr(true);
          setContentErr("Mật khẩu nhập lại không khớp!");
        }
      }
    }
  };

  const checkModal = (value: boolean) => {
    setVisible(value);
  };
  return (
    <>
      <Row gutter={[16, 16]} className="border-bottom-1">
        <Col span={6} style={{ right: 10 }}>
          <Badge
            count={
              <Space>
                <PrivateView permissions={Permissions.Auth.ProfileManage.Edit}>
                  <Upload {...uploadProps}>
                    <Button
                      icon={<FontAwesomeIcon icon={faEdit} color="#3699FF" />}
                      className="border-0  bg-transparent shadow-none"
                    />
                  </Upload>
                </PrivateView>
              </Space>
            }
          >
            {/* {datas?.fileExtension ? ( */}
            <Avatar
              shape="square"
              src={getSource(datas)}
              size={100}
              className="p-1"
              style={{
                backgroundColor: "var(--light-color)",
              }}
            />
          </Badge>
        </Col>
        <Col span={18} className="px-2">
          <Row justify="space-between">
            <Col>
              <div className="d-flex justify-content-between align-items-center">
                <Title level={4}>{info?.account.userName ?? ""}</Title>
                <Image
                  src={
                    info?.account.gender
                      ? Images.user.boy_gender
                      : Images.user.girl_gender
                  }
                  preview={false}
                  className="ml-1 mb-2"
                />
              </div>
            </Col>
            <Col>
              {info?.account.status !== undefined && (
                <div className="d-flex justify-content-around align-items-center">
                  <Tags color={backgroundColor}>
                    <Text style={{ color }}>{label}</Text>
                  </Tags>
                </div>
              )}
            </Col>
          </Row>
          <div className="d-flex align-items-center pb-2">
            <Image src={Images.user.gift} preview={false} />
            <Text className="px-3">
              {info?.account?.extraProperties?.Birthday ?? info?.birthday
                ? dayjs(
                    info.account?.extraProperties?.Birthday ?? info?.birthday
                  ).format("DD/MM/YYYY")
                : ""}
            </Text>
          </div>
          <div className="d-flex align-items-center pb-2">
            <Image src={Images.user.email} preview={false} />
            <Text className="px-3">{info?.account.email ?? ""}</Text>
          </div>
          <div className="d-flex align-items-center pb-2">
            <Image src={Images.user.phone} preview={false} />
            <Text className="px-3">{info?.account.phoneNumber ?? ""}</Text>
          </div>
          <div className="d-flex align-items-center pb-2">
            <Image src={Images.user.phoneCard} preview={false} />
            <Text className="px-3">
              {info?.account?.extraProperties?.IdentityCard ?? ""}
            </Text>
          </div>
          <div className="d-flex align-items-center pb-2">
            <Image src={Images.user.location} preview={false} />
            <Text className="px-3">
              {info?.account?.extraProperties?.Address ?? ""}
            </Text>
          </div>
          <div className="d-flex align-items-center pb-2">
            <Image src={Images.user.calculator} preview={false} />
            <Text className="px-3">{roles?.join(", ") ?? ""}</Text>
          </div>
          <div className="d-flex align-items-center pb-2">
            <Image src={Images.user.warehouse_01} preview={false} />
            <Text className="px-3">
              {filterArchive?.archivesInCharge ?? ""}
            </Text>
          </div>
          <div className="d-flex align-items-center pb-2">
            <Image src={Images.user.warehouse_02} preview={false} />
            <Text className="px-3">
              {filterArchive?.listArchiveLookup?.join(", ") ?? ""}
            </Text>
          </div>
        </Col>
      </Row>
      <Row justify="space-between" className="pt-3">
        <Col flex={1}>
          <PrivateView permissions={Permissions.Auth.ProfileManage.Edit}>
            <Button
              className="w-100"
              style={{ backgroundColor: "#00BEA4", color: "white" }}
              onClick={() => {
                setVisible(true);
                checkOf(false);
              }}
            >
              Cập nhật thông tin
            </Button>
          </PrivateView>
        </Col>
        <Col flex={0.2} />
        <Col flex={1}>
          <Button
            className="w-100"
            style={{
              backgroundColor: "rgba(0, 59, 115, 0.8)",
              color: "white",
            }}
            onClick={() => setIsModalVisible(true)}
          >
            Đổi mật khẩu
          </Button>
        </Col>
      </Row>
      <Drawer
        title="Cập nhật thông tin"
        placement="right"
        closable={false}
        onClose={() => setVisible(false)}
        visible={visible}
        contentWrapperStyle={{
          top: "64px",
          height: "88vh",
          borderRadius: 10,
          width: 450,
        }}
        destroyOnClose
      >
        <UpdateProfile
          checkModal={checkModal}
          info={info}
          checkNewLoad={checkNewLoad}
        />
      </Drawer>
      <Modal
        title="Đổi mật khẩu"
        visible={isModalVisible}
        onOk={form.submit}
        onCancel={() => {
          form.resetFields();
          setIsModalVisible(false);
        }}
        width={370}
        style={{ marginRight: 40 }}
      >
        <Form layout="vertical" onFinish={onFinish} form={form}>
          <Item
            name="currentPassword"
            label="Mật khẩu cũ"
            validateStatus={isErrOld ? "error" : ""}
            help={isErrOld ? contentErrOld : ""}
            style={{ margin: 0 }}
          >
            <Input.Password
              onChange={(e: any) => {
                if (e.target.value === "") {
                  setContentErrOld("Vui lòng nhập mật khẩu");
                  setIsErrOld(true);
                } else {
                  setIsErrOld(false);
                }
              }}
            />
          </Item>
          <Item
            name="newPassword"
            label="Mật khẩu mới"
            validateStatus={isErrNew ? "error" : ""}
            help={isErrNew ? contentErrNew : ""}
            style={{ margin: 0 }}
          >
            <Input.Password
              onChange={(e: any) => {
                if (e.target.value === "") {
                  setContentErrNew("Vui lòng nhập mật khẩu");
                  setIsErrNew(true);
                } else {
                  setIsErrNew(false);
                }
              }}
            />
          </Item>
          <Item
            name="checkNewPassword"
            label="Nhập lại mật khẩu mới"
            validateStatus={isErr ? "error" : ""}
            help={isErr ? contentErr : ""}
            style={{ margin: 0 }}
          >
            <Input.Password
              onChange={(e: any) => {
                if (e.target.value === "") {
                  setContentErr("Vui lòng nhập mật khẩu");
                  setIsErr(true);
                } else {
                  setIsErr(false);
                }
              }}
            />
          </Item>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProp = (state: RootReducer) => {
  return {
    profileReducer: state.profileModule,
    archivesReducer: state.archives,
  };
};
const mapDispatchToProps = (dispatch: (arg0: Action<any>) => any) => {
  return {
    changePasswordScreen: (param: ChangePasswordRequest) =>
      dispatch(profileModule.actions.changePassword.start(param)),
    updateProfileScreen: (param: AddUserRequest) =>
      dispatch(profileModule.actions.updateProfile.start(param)),
    getAllSelectScreen: () =>
      dispatch(ArcchivesModule.actions.getArchivesSelect.start()),
  };
};
export default connect(mapStateToProp, mapDispatchToProps)(UserProfileDrawer);
