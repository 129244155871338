const env = {
  APP_NAME: "",
  DEFAULT_PAGE_SIZE: 10,
  API_URL: "http://localhost",
  API_VERSION: 1,
} as ENV;

for (const key in process.env) {
  if (key.indexOf("REACT_APP_") === 0) {
    const newKey = key.replace("REACT_APP_", "");
    if (!process.env[newKey]) {
      env[newKey] = process.env[key];
    }
    continue;
  }

  env[key] = process.env[key];
}

export default env;
