import { Col, Form, Input, message, Row } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, DatePicker } from "src/components";
import Select from "src/components/Select";
import { useDidUpdate, useLoading } from "src/core/hooks";
import { Action } from "src/core/models/redux";
import { ReduxObservableModuleReducer } from "src/core/redux/ReduxObservableModule";
import { AddUserRequest } from "src/models/administration/User";
import {
  AllParentFieldsRequest,
  GetCategoryNamesRequest,
  GetCategoryNamesResponse,
} from "src/models/directorys/directory";
import { RootReducer } from "src/redux";
import directorysModule from "src/redux/modules/directorys";
import profileModule from "src/redux/modules/profile";
import { patternNumber } from "src/utilities/patternHelper";

const { Item } = Form;
interface updateFrofileProps {
  updateProfileScreen: (params: AddUserRequest) => any;
  getTableCategoryScreen: (params: AllParentFieldsRequest) => any;
  getProfile: () => any;
  profileReducer: ReduxObservableModuleReducer;
  directoryReducer: ReduxObservableModuleReducer;
  checkModal: (param: boolean) => any;
  checkNewLoad: (param: boolean) => any;
  getCategoryNames: (param: GetCategoryNamesRequest) => any;
  info: any;
}

const UpdateProfile = (props: updateFrofileProps) => {
  const {
    updateProfileScreen,
    getTableCategoryScreen,
    getProfile,
    profileReducer,
    directoryReducer,
    checkModal,
    checkNewLoad,
    info,
    getCategoryNames,
  } = props;
  const [form] = Form.useForm();
  const [city, setCity] = useState([]);
  const [districtData, setDistrictData] = useState<any>([]);
  const infos = info?.account;
  const profileList = {
    name: infos?.name ? infos?.name : "",
    birthday: infos?.birthday ? dayjs(infos?.birthday) : "",
    gender: infos?.gender === true ? "true" : "false",
    email: infos?.email ? infos?.email : "",
    phoneNumber: infos?.phoneNumber ? infos?.phoneNumber : "",
    identityCard: infos?.identityCard ? infos?.identityCard : "",
    address: infos?.address ? infos?.address : "",
    issueDate: infos?.issueDate ? dayjs(infos?.issueDate) : "",
    province: infos?.province ? infos?.province : "",
    placeOfIssue: infos?.placeOfIssue ? infos?.placeOfIssue : "",
    district: infos?.district ? infos?.district : "",
    publicId: infos?.publicId ? infos?.publicId : "",
    fileExtension: infos?.fileExtension ? infos?.fileExtension : "",
  };
  useEffect(() => {
    getTableCategoryScreen({ TypeCodeFilter: "Province", MaxResultCount: 100 });
    getProfile();
    if (info) {
      form.setFieldsValue(profileList);
    }
    if (info?.account?.province) {
      getTableCategoryScreen({
        ParentCodeFilter: info?.account?.province,
        TypeCodeFilter: "District",
        MaxResultCount: 100,
      });
    }
  }, [info]);

  useDidUpdate(() => {
    const { state } = directoryReducer;
    const {
      reducerStates: { getTableCategory },
    } = directorysModule;
    if (getTableCategory.success === state) {
      for (let i = 0; i < directoryReducer.data.items.length; i++) {
        if (
          directoryReducer.data.items[i].categories.typesCode === "Province"
        ) {
          setCity(directoryReducer.data.items);
        } else {
          setDistrictData(directoryReducer.data.items);
        }
      }
    }
  }, [directoryReducer]);

  useDidUpdate(() => {
    const { state } = profileReducer;
    const {
      reducerStates: { updateProfile, getProfile },
    } = profileModule;
    switch (state) {
      case getProfile.success:
        break;
      case updateProfile.success:
        form.resetFields();
        checkNewLoad(true);
        checkModal(false);
        message.success("Cập nhật thành công");
        break;
      case updateProfile.failed:
        message.error("Cập nhật thất bại");
        break;
    }
  }, [profileReducer]);
  const onFinish = (values: any) => {
    const gender = values.gender === "true";
    values.gender = gender;
    updateProfileScreen(values);
  };

  const onSelect = (value: any) => {
    getTableCategoryScreen({
      ParentCodeFilter: value,
      TypeCodeFilter: "District",
      MaxResultCount: 100,
    });
  };

  // Fetch default province and district
  const { startLoading, stopLoading } = useLoading();
  const [extraProvinceOptions, setExtraProvinceOptions] = useState<
    { value: string; label: string }[]
  >([]);
  const [extraDistrictOptions, setExtraDistrictOptions] = useState<
    { value: string; label: string }[]
  >([]);

  useEffect(() => {
    // Get extra category names
    const categories: GetCategoryNamesRequest = [];

    form.getFieldValue("province") &&
      categories.push({
        code: form.getFieldValue("province"),
        typeCode: "province",
      });
    form.getFieldValue("district") &&
      categories.push({
        code: form.getFieldValue("district"),
        typeCode: "district",
      });

    getCategoryNames(categories);
  }, []);

  useDidUpdate(() => {
    const {
      processing,
      success,
      failed,
    } = directorysModule.reducerStates.getCategoryNames;
    const jobs = {
      [processing]: startLoading,
      [success]: () => {
        const cates = directoryReducer.data as GetCategoryNamesResponse;

        setExtraProvinceOptions(
          cates
            .filter((cate) => cate.code === form.getFieldValue("province"))
            .map((cate) => ({
              value: cate.code,
              label: cate.name,
            }))
        );

        setExtraDistrictOptions(
          cates
            .filter((cate) => cate.code === form.getFieldValue("district"))
            .map((cate) => ({
              value: cate.code,
              label: cate.name,
            }))
        );

        stopLoading();
      },
      [failed]: stopLoading,
    };

    directoryReducer.state &&
      jobs[directoryReducer.state] &&
      jobs[directoryReducer.state]();
  }, [directoryReducer]);
  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      // initialValues={profileList}
    >
      <Item name="id" hidden>
        <Input />
      </Item>
      <Item name="publicId" hidden>
        <Input />
      </Item>
      <Item name="fileExtension" hidden>
        <Input />
      </Item>
      <Row justify="space-between">
        <Col xs={18} md={18} xl={18} xxl={18}>
          <Item
            name="name"
            label="Họ tên"
            rules={[{ required: true, message: "Họ tên được yêu cầu" }]}
          >
            <Input placeholder="Họ tên" />
          </Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col xs={11} md={11} xl={11} xxl={11}>
          <Item name="birthday" label="Ngày sinh">
            <DatePicker
              placeholder="DD/MM/YYYY"
              className="w-100"
              format="DD/MM/YYYY"
            />
          </Item>
        </Col>
        <Col xs={11} md={11} xl={11} xxl={11}>
          <Item name="gender" initialValue="true" label="Giới tính">
            <Select
              options={[
                {
                  label: "Nam",
                  value: "true",
                },
                {
                  label: "Nữ",
                  value: "false",
                },
              ]}
            />
          </Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col xs={11} md={11} xl={11} xxl={11}>
          <Item name="email" label="Email">
            <Input placeholder="Nhập email" />
          </Item>
        </Col>
        <Col xs={11} md={11} xl={11} xxl={11}>
          <Item
            name="phoneNumber"
            label="Số điện thoại"
            // rules={[
            //   {
            //     pattern: patternPhoneNumber,
            //     message: "Số điện thoại không đúng định dạng",
            //   },
            // ]}
          >
            <Input placeholder="Số điện thoại" />
          </Item>
        </Col>
      </Row>
      <Row justify="space-between">
        <Col xs={11} md={11} xl={11} xxl={11}>
          <Item
            name="identityCard"
            label="CMND"
            rules={[
              { pattern: patternNumber, message: "CMND yêu cầu là kiểu số" },
            ]}
          >
            <Input placeholder="CMND" />
          </Item>
        </Col>
        <Col xs={11} md={11} xl={11} xxl={11}>
          <Item name="issueDate" label="Ngày cấp">
            <DatePicker
              placeholder="DD/MM/YYYY"
              className="w-100"
              format="DD/MM/YYYY"
            />
          </Item>
        </Col>
      </Row>
      <Item name="placeOfIssue" label="Nơi cấp">
        <Input placeholder="Nơi cấp CMND" />
      </Item>
      <Row justify="space-between">
        <Col xs={11} md={11} xl={11} xxl={11}>
          <Item name="province" label="Thành phố">
            <Select
              placeholder="Chọn thành phố"
              options={[
                ...city.map((i: any) => ({
                  value: i.categories.code,
                  label: i.categories.title,
                })),
                ...extraProvinceOptions,
              ]}
              onSelect={onSelect}
              // fetchFunc={fetchProvices}
            />
          </Item>
        </Col>
        <Col xs={11} md={11} xl={11} xxl={11}>
          <Item noStyle shouldUpdate>
            {(form) => {
              let disabled = false;
              if (form.getFieldValue("province") === undefined) {
                disabled = true;
              }
              return (
                <Item name="district" label="Quận/Huyện">
                  <Select
                    placeholder="Chọn quận huyện"
                    options={[
                      ...districtData.map((i: any) => ({
                        value: i.categories.code,
                        label: i.categories.title,
                      })),
                      ...extraDistrictOptions,
                    ]}
                    disabled={disabled}
                  />
                </Item>
              );
            }}
          </Item>
        </Col>
      </Row>
      <Item name="address" label="Địa chỉ">
        <Input placeholder="Địa chỉ chi tiết" />
      </Item>
      <Item wrapperCol={{ offset: 10, span: 16 }}>
        <Button
          htmlType="button"
          onClick={() => {
            checkModal(false);
            form.resetFields();
          }}
          style={{ marginRight: 10, width: 100 }}
        >
          Huỷ bỏ
        </Button>
        <Button type="primary" htmlType="submit" style={{ width: 100 }}>
          Lưu
        </Button>
      </Item>
    </Form>
  );
};

const mapStateToProp = (state: RootReducer) => {
  return {
    profileReducer: state.profileModule,
    directoryReducer: state.directory,
  };
};

const mapDispatchToProps = (dispatch: (arg0: Action<any>) => any) => ({
  updateProfileScreen: (param: AddUserRequest) =>
    dispatch(profileModule.actions.updateProfile.start(param)),
  getTableCategoryScreen: (params: AllParentFieldsRequest) =>
    dispatch(directorysModule.actions.getTableCategory.start(params)),
  getProfile: () => dispatch(profileModule.actions.getProfile.start()),
  getCategoryNames: (params: GetCategoryNamesRequest) =>
    dispatch(directorysModule.actions.getCategoryNames.start(params)),
});

export default connect(mapStateToProp, mapDispatchToProps)(UpdateProfile);
