import { UploadFile } from "antd/lib/upload/interface";
import { Dayjs } from "dayjs";
import { ListRequest, ListResponse } from "src/models/Request";

// Record type
export enum PreservationOfRecordType {
  Coordinate = 1,
  Cadastral = 2,
  Other = 3,
}

export const preservationOfRecordTypes = Object.values(
  PreservationOfRecordType
).filter((type) => typeof type !== "string") as PreservationOfRecordType[];

export function getPreservationOfRecordTypeText(
  type: PreservationOfRecordType
) {
  switch (type) {
    case PreservationOfRecordType.Cadastral:
      return "Đất đai >> Hồ sơ địa chính";
    case PreservationOfRecordType.Coordinate:
      return "Đất đai >> Hồ sơ điểm tọa độ";
    case PreservationOfRecordType.Other:
      return "Hồ sơ khác";
  }
}

// Owner type
export enum PreservationOfRecordOwnerType {
  Family = 0,
  Organization = 1,
}

export const preservationOfRecordOwnerTypes = Object.values(
  PreservationOfRecordOwnerType
).filter((type) => typeof type !== "string") as PreservationOfRecordOwnerType[];

export function getPreservationOfRecordOwnerTypeText(
  type: PreservationOfRecordOwnerType
) {
  switch (type) {
    case PreservationOfRecordOwnerType.Family:
      return "Hồ sơ hộ gia đình/cá nhân";
    case PreservationOfRecordOwnerType.Organization:
      return "Hồ sơ tổ chức";
  }
}

// Status
export enum PreservationOfRecordStatus {
  Using = 1,
  Expired = 2,
}

export const preservationOfRecordStatuses = Object.values(
  PreservationOfRecordStatus
).filter((type) => typeof type !== "string") as PreservationOfRecordStatus[];

export function getPreservationOfRecordStatusText(
  status: PreservationOfRecordStatus
) {
  switch (status) {
    case PreservationOfRecordStatus.Using:
      return "Đang sử dụng";
    case PreservationOfRecordStatus.Expired:
      return "Đã hết hạn";
  }
}

// Storage time type
export enum PreservationOfRecordStorageTimeType {
  Infinity = 1,
  TimeLimited = 2,
}

export const preservationOfRecordStorageTimeTypes = Object.values(
  PreservationOfRecordStorageTimeType
).filter(
  (type) => typeof type !== "string"
) as PreservationOfRecordStorageTimeType[];

export function getPreservationOfRecordStorageTimeTypeText(
  type: PreservationOfRecordStorageTimeType
) {
  switch (type) {
    case PreservationOfRecordStorageTimeType.Infinity:
      return "Vĩnh viễn";
    case PreservationOfRecordStorageTimeType.TimeLimited:
      return "Có thời hạn";
  }
}

export enum PreservationOfRecordFileType {
  Paper = 1,
  Tech = 2,
  Specialize = 3,
  Personal = 4,
  Listen = 5,
  Electronic = 6,
}

export const preservationOfRecordFileTypes = Object.values(
  PreservationOfRecordFileType
).filter((type) => typeof type !== "string") as PreservationOfRecordFileType[];

export function getPreservationOfRecordFileTypeText(
  type: PreservationOfRecordFileType
) {
  switch (type) {
    case PreservationOfRecordFileType.Paper:
      return "Tài liệu giấy";
    case PreservationOfRecordFileType.Tech:
      return "Tài liệu khoa học kỹ thuật";
    case PreservationOfRecordFileType.Specialize:
      return "Tài liệu chuyên môn";
    case PreservationOfRecordFileType.Personal:
      return "Tài liệu cá nhân/gia đình/dòng họ";
    case PreservationOfRecordFileType.Listen:
      return "Tài liệu nghe nhìn";
    case PreservationOfRecordFileType.Electronic:
      return "Tài liệu điện tử";
  }
}

export interface PreservationOfRecord<
  TimeType extends any = Dayjs,
  FileType extends any = UploadFile[]
> {
  id: string;
  preservationOfRecordType: PreservationOfRecordType;
  title: string;
  fieldCode: string;
  province: string;
  district: string;
  ward: string;
  note: string;

  status: PreservationOfRecordStatus;
  year?: number | null;
  code: string;
  indexOfNumbers: string;
  fileNumber: string;
  fromTime?: TimeType;
  toTime?: TimeType;
  sheetNumber?: number;
  storageTimeType: PreservationOfRecordStorageTimeType;
  storageYear?: number;
  fileType: PreservationOfRecordFileType;
  archivesId: string;
  storageRack: string;
  storageSection: string;
  storageBox: string;
  boxNo?: string | number;

  licenseSheetNumber: number;
  licenseNumberFrom: number;
  licenseNumberTo: number;
  licenseSameNumber: string;
  licenseNumberOfDefects: string;
  licenseIndexOfNumbers?: string;
  licenseScheduler: string;
  licenseDate?: string | Dayjs;
  licenseContent: string;

  files: FileType;
}

export interface PreservationOfRecordCoordinate<
  TimeType extends any = Dayjs,
  FileType extends any = UploadFile[]
> {
  itemNo?: number;
  symbol: string;
  name?: string;
  horizontalAxis?: number;
  verticalAxis?: number;
  height?: number;
  district?: string;
  districtName?: string;
  ward?: string;
  wardName?: string;
  coordinatePointType?: string;
  updateDate?: TimeType;
  updater?: string;
  files: FileType;
  preservationOfRecordId?: string;
}

export interface PreservationOfRecordOwner {
  ownerType: PreservationOfRecordOwnerType;
  projectName: string;
  ownerName: string;
  ownerIdentification?: string | number;
  ownerPhone?: string;
  ownerAddress?: string;
  ownerName2: string;
  ownerIdentification2?: string | number;
  ownerPhone2?: string;
  ownerAddress2?: string;
  note?: string;
  preservationOfRecordId: string;
}

export interface PreservationOfRecordParcelOfLand {
  itemNo: number;
  licenseNumber: string;
  mapNo: string;
  lotNo: string;
  note: string;
  preservationOfRecordId: string;
}
export interface PreservationOfRecordAddress {
  id: string;
  province: string;
  district: string;
  ward: string;
  provinceName: string;
  districtName: string;
  wardName: string;
  preservationOfRecordId: string;
}

export interface PreservationOfRecordRequest<
  TimeType extends any = string,
  FileType extends any = string
> extends PreservationOfRecord<TimeType, FileType> {
  owner?: PreservationOfRecordOwner;
  coordinatePoints: PreservationOfRecordCoordinate<TimeType, FileType>[];
  parcelOfLands: PreservationOfRecordParcelOfLand[];
  addresses: PreservationOfRecordAddress[];
}

export interface PreservationOfRecordResponse {
  creationTime: string;
  preservationOfRecord: PreservationOfRecord<string, string>;
  archivesName: string | null;
  owner: PreservationOfRecordOwner | null;
  coordinatePoints: PreservationOfRecordCoordinate<string, string>[];
  parcelOfLands: PreservationOfRecordParcelOfLand[];
  addresses: PreservationOfRecordAddress[];
  isFileDownload: boolean;
}

export interface PreservationOfRecordListRequest extends ListRequest {
  archivesIdFilter?: string;
  storageBoxFilter?: string;
  storageTimeTypeFilter?: number;
  fromTime?: string | Dayjs;
  toTime?: string | Dayjs;
  excludePreservationOfRecordIds?: string;
  ownerTypeFilter: PreservationOfRecordOwnerType;
  fieldFilter?: string;
}

export type PreservationOfRecordListResposne = ListResponse<PreservationOfRecordResponse>;

export interface ImportRequest {
  file: File;
  sheet: number;
  ownerType: PreservationOfRecordOwnerType;
}

export type ImportResponse = {
  success: boolean;
  message: null | string[];
  result: {
    stt: number;
    preservationOfRecordId: string;
    documentId: string;
    success: boolean;
    error: string[];
  }[];
  totalSuccess: number;
  totalFail: number;
  importType: string;
}[];
