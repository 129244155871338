import { message } from "antd";
import { ActionsObservable, combineEpics, Epic } from "redux-observable";
import { catchError } from "rxjs/operators";
import { i18n } from "src/localization";
import authModule from "src/redux/modules/auth";
import directorysModule from "src/redux/modules/directorys";
import administrationModule from "src/redux/modules/administration";
import archiverModule from "src/redux/modules/archives";
import accountModule from "src/redux/modules/account";
import advancedSearchModule from "src/redux/modules/advancedSearch";
import preservationOfRecordModule from "src/redux/modules/preservationOfRecord";
import reportModule from "src/redux/modules/report";
import profileModule from "src/redux/modules/profile";
import documentModule from "src/redux/modules/document";
import phongsModule from "src/redux/modules/phongs";
import dashboardModule from "src/redux/modules/dashboard";
import organizationUnitsModule from "src/redux/modules/organizationUnits";
import roleModule from "src/redux/modules/role";
import readersModule from "src/redux/modules/readers";
import circulationDocumentModule from "src/redux/modules/circulationDocument";
import contractModule from "src/redux/modules/contract";
import dataRequestFormModule from "src/redux/modules/dataRequestForm";

export const rootEpic: Epic = (
  action$: ActionsObservable<any>,
  store$,
  dependencies
) =>
  combineEpics(
    ...authModule.epics,
    ...accountModule.epics,
    ...directorysModule.epics,
    ...administrationModule.epics,
    ...archiverModule.epics,
    ...advancedSearchModule.epics,
    ...preservationOfRecordModule.epics,
    ...reportModule.epics,
    ...profileModule.epics,
    ...documentModule.epics,
    ...phongsModule.epics,
    ...dashboardModule.epics,
    ...organizationUnitsModule.epics,
    ...roleModule.epics,
    ...readersModule.epics,
    ...circulationDocumentModule.epics,
    ...contractModule.epics,
    ...dataRequestFormModule.epics
  )(action$, store$, dependencies).pipe(
    catchError((_, source) => {
      message.error(i18n.t("errors:server_error"));
      return source;
    })
  );
